import React from "react";
import Layout from "../../components/Layout";
import FoundationPage from "../../components/FoundationPage";
import { Divider } from "../../components/Divider";

export default function WritingGuidelinesPage({ location }) {
  return (
    <Layout location={location}>
      <FoundationPage
        pageTitle="Writing Guidelines"
        // link1title="View in Figma"
        // link1url="https://www.figma.com/file/08RyCY34fSWYCxwrFWbojr/Global-Design-System-(Beta)?node-id=1039%3A20056"
        // link2title="View MAF Storybook"
        // link2url="https://webcomponents.maf.nielsenmedia.com/gds/?path=/docs/components-typography-text--preview"
        tocItems={[
          "Content Style Guide",
          "Grammar and Style",
          "Product Writing",
        ]}
        description="Concise and well-written copy can make or break a design. If copy is inconsistent or messy, it can affect a user's understanding and trust in an application."
      >
        <div className="section" id="content-style-guide">
          <h2>Content Style Guide</h2>
          <p>
            Nielsen’s marketing team has created documentation to align our
            company’s writing style and brand voice.
          </p>
          <p>
            The Content Style Guide lists the company’s preferred style for
            copy, grammar usage, and industry terminology. We follow the
            Associated Press Stylebook and Merriam-Webster Collegiate
            Dictionary, 11th Edition. Explanations and exceptions are noted in
            the document.
          </p>
          <p>
            Please read the Style Guide to better understand and adhere to our
            standards for communication and copywriting.
          </p>

          <gds-link
            size="600"
            href="https://drive.google.com/file/d/13l5tIGCnDTm_s3eRX5GthmAurGoZIHG7/view"
            underlined="true"
          >
            Read Nielsen's Content Style Guide
          </gds-link>
          <p className="mt-space-700">
            To learn about Nielsen’s brand voice, consult the Nielsen Brand
            Voice & Personality Guidelines.
          </p>
          <gds-link
            size="600"
            href="https://docs.google.com/presentation/d/1k696CnuOFfeGPhsGcxo_Dwvcv9w2faoJPUiJ_SG8KRQ/edit?usp=sharing"
            underlined="true"
          >
            Read Nielsen Brand Voice & Personality Guidelines
          </gds-link>
        </div>
        <Divider />
        <div className="section" id="grammar-and-style">
          <h2>Grammar and Style</h2>
          <p2 className="text-gray-600">
            Note: Nielsen data comes in a myriad of languages, capitalization,
            and formatting. In many cases, you will not have the ability to
            reformat data. Do the best that you can with the data provided. If
            technically impossible at the time, these principles can be ignored.
          </p2>
          <h3>Capitalization</h3>
          <p className="description">
            Proper capitalization is a small but very important part of UX
            copywriting. Nearly all copy is either title case or sentence case
            and people often have strong opinions about which case should be
            chosen. The only exception where text is all caps is in table
            headings. This is to create more contrast between the column
            headings and table cell content.
          </p>
          <p>Here are the strengths of each case:</p>
          <p2 style={{ fontStyle: "italic" }}>Title Case</p2>
          <ul>
            <li>Visually more symmetrical</li>
            <li>Stands out more easily</li>
            <li>Feels more formal</li>
          </ul>
          <p2 style={{ fontStyle: "italic" }}>Sentence Case</p2>
          <ul>
            <li>More readable</li>
            <li>Simpler capitalization rules compared to title case</li>
            <li>Makes it possible to recognize proper nouns</li>
          </ul>
          <h4>Title Case</h4>
          <p>
            Title case is a style of capitalization traditionally used for the
            titles of books, movies, songs, plays, and other works. All major
            words are capitalized, while minor words (like conjunctions and
            articles) are lowercase. The first and the last word are always
            capitalized.
          </p>
          <p2 style={{ fontStyle: "italic" }}>Title Case UI Elements</p2>
          <ul>
            <li>Navigation Items (Breadcrumbs, Steppers, etc)</li>
            <li>Buttons</li>
            <li>Page Titles, Page Headings, and Section Headings</li>
            <li>Form Labels</li>
            <li>Menus</li>
            <li>Tabs & Context Switchers</li>
            <li>List Headings</li>
          </ul>
          <p>
            For help choosing correct title case usage consult
            <gds-link
              size="600"
              href="titlecaseconverter.com"
              underlined="true"
            >
              titlecaseconverter.com
            </gds-link>
            .
          </p>
          <h4>Sentence Case</h4>
          <p>
            In sentence case style, only the first word of a sentence or title
            and any proper nouns are capitalized. All other words are lowercase.
          </p>
          <p2 style={{ fontStyle: "italic" }}>Sentence Case UI Elements</p2>
          <ul>
            <li>All paragraph text</li>
            <li>Helper text</li>
            <li>Form placeholders</li>
            <li>Links</li>
            <li>Message and Empty state descriptions</li>
          </ul>
          <p>
            Some components such as Chips, Checkboxes, Radios, Switches, can be
            either title or sentence case depending on the context of the
            element. Default to title case, but sentence case is acceptable.
          </p>

          <h3>Other Capitalization Rules</h3>
          <ul>
            <li>
              For product names and objects, only capitalize products, brands,
              and sub-brands.
            </li>
            <li>Helper text</li>
            <li>
              File types are capitalized when talking about the kind of file,
              lowercase as the extension of a filename. (Example: CSV and
              text.csv)
            </li>
            <li>
              For list-like content, items in a list always start with a capital
              letter, even if the bullet is an incomplete sentence.
            </li>
            <li>The names of languages are always capitalized.</li>
          </ul>
          <h3>Dates and Times</h3>
          <p>
            There are two main ways to display date/time data: exact times and
            relative times. Use whichever best fits the data you’re trying to
            communicate.
          </p>
          <ul>
            <li>
              Exact dates/times refer to a point in time. (Example: September
              15th, 2022 5:44PM)
            </li>
            <li>
              Relative dates/times communicate a sense of recency or urgency.
              (Example: 15m ago)
            </li>
          </ul>
          <p>
            Most dates in our applications will be displayed as exact times.
            Relative times are usually best if the date is recent. They’re also
            best if the user action is required in a given timeframe.{" "}
          </p>
          <h4>Displaying Relative Times</h4>
          <ul>
            <li>
              <b>Less than one minute: </b>Just now
            </li>
            <li>
              <b>Less than one hour: </b>27 min ago or 27m
            </li>
            <li>
              <b>Less than one day: </b>4 hours ago or 4h
            </li>
            <li>
              <b>Less than one month: </b>12 days ago or 12d
            </li>
            <li>
              <b>Less than one month: </b>(Use the exact time: Sep 15th)
            </li>
          </ul>
          <h4>Displaying Exact Dates</h4>

          <p>
            Displaying exact dates and times can be confusing depending on the
            user’s location and background. For this reason, we’re consolidating
            the ways we display our dates and times into these standards:{" "}
          </p>
          <p2 style={{ fontStyle: "italic" }}>For Numbers Only:</p2>
          <ul>
            <li>
              <b>ISO 8601:</b> 2022-08-15, 14:30 PM
            </li>
            <li>
              <b>American formatting:</b> 08/15/2022, 2:30 PM
            </li>
          </ul>

          <p2 style={{ fontStyle: "italic" }}>For Mixed Case:</p2>
          <ul>
            <li>
              <b>ISO 8601:</b> 15th August 2022, 14:30 PM
            </li>
            <li>
              <b>American formatting:</b> August 15th 2022, 2:30 PM
            </li>
          </ul>
          <p>
            ISO 8601 is an international standard that aligns very closely with
            how much of the world formats dates and time. ISO 8601 is very
            uncommon to most Americans so the American formatting is available
            where applicable. Whenever possible, these options should be
            configurable by user preference or localization.
          </p>
          <p>
            Using the whole date or time is not required. Use the shortest data
            that meets your needs.
          </p>
          <p>
            For more information on date formatting, check out{" "}
            <gds-link
              href="https://en.wikipedia.org/wiki/ISO_8601"
              size="600"
              underlined="true"
            >
              ISO 8601 on Wikipedia
            </gds-link>{" "}
            and{" "}
            <gds-link
              size="600"
              href="https://en.wikipedia.org/wiki/Date_format_by_country"
              underlined="true"
            >
              Date format by country on Wikipedia.
            </gds-link>
          </p>

          <h3>Numbers</h3>
          <p>
            Always use numerals in headlines, headings, and data visualizations.
            In body copy, use numerals for numbers greater than 10, percentages,
            or when numbers need to stand out from the text.
          </p>
          <p>
            Whenever possible, abbreviate large numbers to single digits,
            especially in metrics or KPIs. This makes numbers more scannable,
            more understandable, and saves space.
          </p>
          <ul>
            <li>Substitute K for thousands ($2K for $2 thousand or $2,000)</li>
            <li>
              Substitute M for millions ($88M for $88 million, or $88,000,000)
            </li>
            <li>
              Substitute B for billions ($6.2B for $6.2 billion, or
              $6,200,000,000)
            </li>
            <li>
              Substitute T for trillions ($1T for $1 trillion, or
              $1,000,000,000,000)
            </li>
          </ul>
        </div>
        <Divider />

        <div className="section" id="product-writing">
          <h2>Product Writing</h2>
          <p>
            Well written labels for actions usually contain a verb. Strive for
            conciseness. Using either a single verb or a verb and a noun if
            possible. For example, “Edit Campaign” is better than “Campaign
            Details”. In some cases, single nouns like “Views”, “Options” or
            “Layout” are acceptable too.
          </p>
          <p>
            We prefer the verbs, Create, Open, Add, Edit, Exit, and Continue as
            the starting word for actions. Labels like “Create Scenario”, “Add
            to Selected”, “Edit Plan”, “Exit Report”, and “Continue Setup” are
            great labels. Use repetition to reinforce the action the user needs
            to take. In most cases, you should use the same verb throughout the
            whole user flow.
          </p>
          <h3>Actions and Decisions</h3>
          <p>
            Well written labels for actions usually contain a verb. Strive for
            conciseness. Using either a single verb or a verb and a noun if
            possible. For example, “Edit Campaign” is better than “Campaign
            Details”. In some cases, single nouns like “Views”, “Options” or
            “Layout” are acceptable too.
          </p>
          <p>
            We prefer the verbs, Create, Open, Add, Edit, Exit, and Continue as
            the starting word for actions. Labels like “Create Scenario”, “Add
            to Selected”, “Edit Plan”, “Exit Report”, and “Continue Setup” are
            great labels. Use repetition to reinforce the action the user needs
            to take. In most cases, you should use the same verb throughout the
            whole user flow.
          </p>
          <h3>Titles and Headers</h3>
          <p>
            Page titles (H1’s) should almost always be nouns that describe the
            space, not the task. For example, “Report Options”, “Demographics”,
            or “Campaigns List” explain to the user where they are and what
            they’re viewing.
          </p>
          <p>
            Subheadings (H2’s and smaller) mostly follow the same rule about
            describing a space. In some cases, it’s better to tell the task to
            be done. For example, “Select TV Input Method” or “Add ISCI Codes”.
            Alternatively, you can add helper text or information about the
            space to help the user complete their task.
          </p>

          <h3>Messages and Empty States</h3>
          <p>
            We have three different types of user triggered messages: banner,
            dialog, and toast. Each of them have slightly different requirements
            which you can find on their component pages.
          </p>
          <p>
            An exercise you can use to write these messages is to mimic a
            conversation. Think about how you’d explain the action in a
            conversation and then only choose the key verbs and nouns. For
            example, “Are you sure you want me to delete this list?” would be
            “Are you sure you want to delete?” or just “Delete list?”. Sentence
            fragments are encouraged in this case copywriting as long as the
            intended action is clear.
          </p>
          <p>
            When a task is completed successfully, try to convey a sense of
            completion. Be explicit about what was successful by referring to
            the object, file name, campaign, etc. Include helpful follow-on
            actions, where appropriate. Message titles should be sentence case
            and final punctuation should be omitted, unless the title is a
            question. Body copy should be sentence case and final punctuation
            should be included.
          </p>
          <p>
            There are three main reasons for empty states: an error, lack of
            results, or because of user action. Use the title to explain the
            situation, and the body to provide a path forward. In addition,
            focus on the situation, not the user’s behavior. Use phrases like
            “there weren’t” and “it doesn’t.” Focus on what the user can do
            (versus what hasn't been done yet) by reiterating the value
            proposition of the feature.
          </p>
        </div>
        <Divider />
      </FoundationPage>
    </Layout>
  );
}
